import './App.css';
import Bild1 from "./1.js"

import Header from "./header.js"
function App() {
  return (
    <div className="App">

      <Header />
      <hr ></hr>
      <div>
        <h1 >3 ASPEKTE SICHERER & GESETZESKONFORMER<br></br> GEFAHRSTOFFLAGERUNG.</h1>

      </div>

      <div>
        <Bild1 />

      </div>
    </div>
  );
}

export default App;
