import * as React from 'react';
import './App.css';
import Logo1 from "./bilder/Terminal-1-1.jpg"
import Logo2 from "./bilder/Terminal-1-2.jpg"
import Logo3 from "./bilder/Terminal-1-3.jpg"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Dialog } from '@mui/material';
import Header from "./header.js"
import SquareIcon from '@mui/icons-material/Square';
class Bild1 extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        bild1: false,
        bild2: false,
        bild3: false
    }

    render() {
        return (<>
            <div  >
                <div >
                    <img className='bild1' src={Logo1} ></img>
                    <button onClick={() => this.setState({ bild1: true })} className='button1'>Jetzt entdecken<ArrowForwardIosIcon className='arrow1 ' /></button>
                </div>
            </div>

            <div >
                <div  >
                    <img className='bild1' src={Logo2} ></img>
                    <button onClick={() => this.setState({ bild2: true })} className='button2'>Jetzt entdecken<ArrowForwardIosIcon className='arrow2' /></button>
                </div>
            </div>
            <div >
                <div  >
                    <img className='bild1' src={Logo3}></img>
                    <button onClick={() => this.setState({ bild3: true })} className='button3'>Jetzt entdecken<ArrowForwardIosIcon className='arrow3' /></button>
                </div>
            </div>
            <Dialog
                fullScreen={true}
                color="primary" true open={this.state.bild1} onClose={() => this.setState({ bild1: false })}>
                <div className='dialog'>
                    <div>
                        <Header />
                    </div>
                    <hr ></hr>

                    <h1 >SCHUTZ VON MENSCHEN UND UMWELT.</h1>

                    <img className='bild1' src={Logo1} ></img>
                    <h2 style={{ color: "#2061AE" }}>Das sagt der Gesetzgeber zu umweltschützender Lagerung</h2>
                    <div className='textbox' >
                        <p><SquareIcon fontSize='small' /> ⠀⠀  AwSV § 18 „Anforderung an die Rückhaltung wassergefährdener Stoffe“ </p>
                        <p><SquareIcon fontSize='small' /> ⠀⠀  AwSV § 31 (2) „Fass- und Gebindelager müssen über eine Rückhalteeinrichtung mit einem Rückhaltevolumen verfügen, …“  </p>
                        <p><SquareIcon fontSize='small' /> ⠀⠀  TRGS 510 Abs. 4.2 (13) „Behälter mit flüssigen Gefahrstoffen sind in eine Rückhalteeinrichtung zu stellen, …“</p>
                    </div>
                    <div className='linebox'><hr className='lineBottom' ></hr></div>

                    <div className="backbuttondiv">
                        <button className='backButton' onClick={() => this.setState({ bild3: false, bild1: false, bild2: false })} variant="contained">Zurück zur Übersicht</button>
                        <button className='weiterButton' onClick={() => this.setState({ bild2: true })} variant="contained">Weiter</button>

                    </div>
                </div>
            </Dialog>
            <Dialog
                fullScreen={true}
                color="primary" true open={this.state.bild2} onClose={() => this.setState({ bild2: false })}>
                <div className='dialog'>
                    <div>
                        <Header />
                    </div>
                    <hr ></hr>

                    <h1>SCHUTZ VOR UNBEFUGTEM ZUGRIFF.</h1>

                    <img className='bild1' src={Logo2} ></img>
                    <h2 style={{ color: "#2061AE" }}>Das sagt der Gesetzgeber zu zugriffsgeschützter Lagerung</h2>
                    <div className='textbox'>
                        <p><SquareIcon fontSize='small' />⠀⠀TRGS 510 Abs. 4.3 „Zugangsbeschränkung für besondere Gefahrstoffe“ </p>
                        <p><SquareIcon fontSize='small' />⠀⠀TRGS 510 Abs. 4.3 (4) „Die Zugangsbeschränkung nach Absatz 1 und 3 kann u.a. erfüllt werden durch:</p>
                        <p> ⠀⠀⠀⠀1. Lagerung in einem geeigneten, abschließbaren Schrank, …“ </p>
                    </div>
                    <div className='linebox'><hr className='lineBottom' ></hr></div>
                    <div className="backbuttondiv" >
                        <button className='backButton' onClick={() => this.setState({ bild3: false, bild1: false, bild2: false })} variant="contained">Zurück zur Übersicht</button>
                        <button className='weiterButton' onClick={() => this.setState({ bild3: true })} variant="contained">Weiter</button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                fullScreen={true}
                color="primary" true open={this.state.bild3} onClose={() => this.setState({ bild3: false })}>
                <div className='dialog'>
                    <div>
                        <Header />
                    </div>
                    <hr ></hr>

                    <h1>SCHUTZ VOR ENTZÜNDLICHEN STOFFEN.</h1>

                    <img className='bild1' src={Logo3} ></img>
                    <h2 style={{ color: "#2061AE" }}>Das sagt der Gesetzgeber zu brandgeschützter Lagerung</h2>
                    <div className='textbox'>
                        <p><SquareIcon fontSize='small' />  ⠀TRGS 510 Abs. 6 „Besondere Brandschutzmaßnahmen - (2) Werden Flüssigkeiten, Feststoffe,</p>

                        <p> ⠀⠀   Druckgaskartuschen oder Aerosolpackungen in Sicherheitsschränken gemäß Anhang 1 gelagert,</p>

                        <p>  ⠀⠀  gelten die Anforderungen dieses Abschnitts 6 als erfüllt.“</p>
                    </div>
                    <div className='linebox'><hr className='lineBottom' ></hr></div>
                    <div className="backbuttondiv" >
                        <button className='backButton' onClick={() => this.setState({ bild3: false, bild1: false, bild2: false })} variant="contained">Zurück zur Übersicht</button>

                    </div>
                </div>
            </Dialog>
        </>);
    }
}

export default Bild1;