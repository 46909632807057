import * as React from 'react';

import Logo from "./Logo.svg"


class Header extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {

    }

    render() {
        return (<>

            <div >
                <img src={Logo} width="1293px" height="508px" style={{ marginTop: "3em", marginBottom: "3em" }} />
            </div>


        </>);
    }
}

export default Header;